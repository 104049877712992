import React from 'react'
import Header from '../components/Header'
import Swap from '../components/Swap'

export default function SwapPage({ connect, isConnected, address }) {
    
    return (
        <div className="App p-5 flex items-center justify-center flex-col">
            <Header connect={connect} isConnected={isConnected} address={address} />
            <div className="mainWindow">
                <Swap isConnected={isConnected} address={address} />
            </div>
        </div>
    )
}