import React, { useState } from 'react'

export default function Navbar() {
    const [openMenu, setOpenMenu] = useState(false);
    const [showSocials, setShowSocials] = useState(false);
    return (
        <nav className="stickyNav monts bg-bblack">
            <div className="flex items-center justify-between max-w-[1150px] mx-auto p-5 border-b border-opacity-5 border-white">
                <img src={require('../../assets/logo.png')} alt="logo" className="w-10" />
                <div className='mx-auto flex-1 flex-center max-w-[600px] pl-16'>
                    <div className="gap-5 text-xs opacity-50 w-full hidden md:flex items-center justify-between text-center">
                        <a href="https://doc.swapfast.io">Document</a>
                        <a href="https://doc.swapfast.io/contact/support">Support</a>
                        <a href="#roadmap">Roadmap</a>
                        <a href="#tokenomics">Tokenomics</a>
                    </div>
                </div>
                <div className='flex-center gap-2'>
                    <div className='flex-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-orange">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                        </svg>
                        <p className='text-orange'>EN</p>
                    </div>
                    <a href="https://app.swapfast.io" className="bg-orange text-xs py-1 px-7 text-bblack rounded-full hidden md:block">Launch App</a>
                    <div className='md:hidden'>
                        <svg onClick={() => setOpenMenu(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-7 h-10 text-orange ${openMenu && 'hidden'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        <svg onClick={() => setOpenMenu(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-7 h-10 text-orange ${!openMenu && 'hidden'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className={`${openMenu ? 'flex' : 'hidden'} items-center justify-center gap-5 text-gray-300 flex-col text-sm my-5`}>
                <a onClick={() => setOpenMenu(false)} href="https://doc.swapfast.io">Document</a>
                <a onClick={() => setOpenMenu(false)} href="https://doc.swapfast.io/contact/support">Support</a>
                <a onClick={() => setOpenMenu(false)} href="#roadmap">Roadmap</a>
                <a onClick={() => setOpenMenu(false)} href="#tokenomics">Tokenomics</a>
                <a onClick={() => setOpenMenu(false)} href='https://app.swapfast.io' className='w-[60%] text-center py-2 rounded-full text-xs text-black bg-orange font-bold'>Launch App</a>
            </div>
        </nav>
    )
}
