import React from 'react'

export default function Hero() {
  return (
    <div className='bg-bblack w-full min-h-[100vh] flex-center'>
        <div className='flex-center flex-col gap-5 md:pt-28'>
            <div>
                <h1 className='text-2xl md:text-3xl monts text-center font-bold'>The Fastest Cross-Chain <br />Swap for Traders</h1>
                <p className='nunito text-[12px] md:text-sm max-w-[500px] text-center opacity-50 mt-2'>SwapFast revolutionizes the trading experience by offering the quickest <br /> and most efficient cross-chain swaps in the market.</p>
                <div className='flex-center text-xs gap-5 mt-5'>
                    <a href="#" className='rounded-full opacity-65 text-sm border border-white border-opacity-20 py-2 px-7'>Buy SwapFast</a>
                    <a href="https://app.swapfast.io" className="bg-orange text-sm py-2 px-7 text-bblack rounded-full">Launch App</a>
                </div>
            </div>
            <img src={require('../../assets/circles.png')} className='w-[80%] max-w-[600px] mx-auto object-contain absolute bottom-20 md:-bottom-52 opacity-50' />
            <img src={require('../../assets/main.gif')} className='w-[80%] max-w-[600px] mx-auto object-contain z-20' />
        </div>
    </div>
  )
}
