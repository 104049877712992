import React, { useEffect, useState } from 'react'
import Tokenheader from './Tokenheader'
import { chainLists } from './chainLists'
import axios from 'axios';
import { url } from '../../url';

export default function TokenList({ openList, setOpenList, setTokenTwo, setTokenOne }) {

    const [isLoading, setIsLoading] = useState(true);
    const [tokens, setTokens] = useState([]);
    const [chainId, setChainId] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const getTokens = async (chainId) => {
        setChainId(chainId);
        setIsLoading(true);
        try {
            console.log(`${url}/tokens/${chainId}`)
            const res = await axios.get(`${url}/tokens/${chainId}`);
            console.log(res.data);
            setTokens(res.data.slice(0, 300));
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const searchToken = async e => {
        e.preventDefault();
        if(!searchQuery) return;
        setIsLoading(true);
        try {
            const res = await axios.get(`${url}/searchtokens/${chainId}/${searchQuery}`);
            setTokens(res.data.slice(0, 300));
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function getF(){
            await getTokens(1);
        };
        getF();
    }, []);

    const selectToken = token => {
        openList == 1 ? setTokenOne(token) : setTokenTwo(token);
        setOpenList(0);
    }

  return (
    <div className='bg-[#1f2a4a] p-4 rounded-xl w-full max-w-[600px] mx-auto text-gray-200'>
        <div className='w-full flex-center flex-col'>
            <div className='flex-center relative w-full'>
                <svg onClick={() => setOpenList(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 absolute left-0 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
                <h1 className='text-lg monts'>Tokens List</h1>
            </div>
            <form onSubmit={searchToken} className='flex items-center justify-between w-full gap-5 mt-5'>
                <div className='bg-[#0000001c] rounded-xl p-4 monts text-[16px] flex items-center justify-between flex-1'>
                    <input onChange={e => setSearchQuery(e.target.value)} type="text" placeholder='Search name or paste address' className='outline-none bg-transparent w-full' />
                </div>
                <button type='submit' className='pr-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${searchQuery && 'textblue rotate-90'} w-7 h-7 opacity-50`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </button>
            </form>
        </div>
        <div className='w-full flex items-start mt-5 justify-between'>
            <div className='flex items-center justify-start flex-wrap gap-3 w-[30%] md:w-[35%]'>
                {
                    chainLists.map(chain => (
                        <div key={chain.chainId} onClick={getTokens.bind(null, chain.chainId)} className={`${chainId == chain.chainId ? 'bg-[#0000004f] border-[#89d7fb20] border' : 'bg-[#0000001c]'} flex-center flex-col p-2 rounded-lg w-[80px] gap-2 cursor-pointer`}>
                            <img src={chain.img} alt={chain.name} className='h-8 object-contain' />
                            <p className='nunito text-xs text-center'>{chain.name}</p>
                        </div>
                    ))
                }
            </div>
            <div className='p-2 md:p-5 w-[70%] md:w-[65%] rounded-md space-y-5 bg-[#0000001c] max-h-[1000px] md:max-h-[500px] overflow-y-scroll overflow-x-hidden'>
                {
                    isLoading ? 
                    <div className='flex-center flex-col'>
                        <img src={require('../../assets/logo.png')} alt="loader" className='w-10' />
                        <p className='nunito text-center text-xs textblue'>Loading. . .</p>
                    </div> :
                    tokens.map(token => (
                        <div onClick={selectToken.bind(null, token)} key={token?.symbol} className='flex items-center justify-between cursor-pointer'>
                            <div className='flex items-center justify-start gap-2'>
                                <img src={token?.logoURI} alt={token?.symbol} className='h-10 object-contain' />
                                <div className='flex items-start flex-col'>
                                    <h1 className='fonts-bold monts text-sm'>{token?.symbol}</h1>
                                    <p className='text-xs font-light opacity-60 monts'>{token?.name}</p>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 opacity-50">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                            </svg>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}