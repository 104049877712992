import React, { useState } from 'react'
import Header from '../../components/Header'
import Swap from './Swap'
import tokenList from '../../tokenList.json';
import TokenList from './TokenList';

export default function Aggregator({ connect, isConnected, address }) {
    const [tokenOne, setTokenOne] = useState(tokenList[0]);
    const [tokenTwo, setTokenTwo] = useState(tokenList[1]);
    const [openList, setOpenList] = useState(0);
    const url = 'https://crypto.com/__assets/mkt-nav-footer/images/icons/socials';
    return (
        <div className="App p-3 md:p-5 flex items-center justify-center flex-col relative overflow-x-hidden">
            <Header connect={connect} isConnected={isConnected} address={address} />
            <div className="w-full">
                {
                    openList ?
                        <TokenList
                            setOpenList={setOpenList}
                            openList={openList}
                            setTokenOne={setTokenOne}
                            setTokenTwo={setTokenTwo}
                        /> :
                        <Swap
                            isConnected={isConnected}
                            address={address}
                            defaultTokenOne={tokenOne}
                            defaultTokenTwo={tokenTwo}
                            setOpenList={setOpenList}
                            openList={openList}
                        />
                }
            </div>
        </div>
    )
}
