export const chainLists = [
    {
        img: 'https://assets.coingecko.com/asset_platforms/images/279/thumb/ethereum.png?1706606803',
        name: 'Ethereum',
        chainId: 1,
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/arbitrum.svg',
        name: 'Arbitrum',
        chainId: 42161, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/aurora.svg',
        name: 'Aurora',
        chainId: 1313161554, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/avalanche.svg',
        name: 'Avalanche',
        chainId: 43114,
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/base.svg',
        name: 'Base',
        chainId: 8453, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/binance.svg',
        name: 'Binance',
        chainId: 56, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/zksync-era.svg',
        name: 'ZkSync',
        chainId: 324, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/fantom.svg',
        name: 'Fantom',
        chainId: 250, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/gnosis.svg',
        name: 'Gnosis',
        chainId: 100, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/klaytn.svg',
        name: 'Klaytn',
        chainId: 8217, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/optimism.svg',
        name: 'Optimism',
        chainId: 10, 
    },
    {
        img: 'https://portal.1inch.dev/assets/icons/network-logos/polygon.svg',
        name: 'Polygon',
        chainId: 137, 
    },
];