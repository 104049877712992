import React, { useRef, useState } from 'react'

export default function Footer() {
    const url = 'https://crypto.com/__assets/logo.png-footer/images/icons/socials';
    const [footItem, setFootItem] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const email = useRef();
    const subscribe = async e => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // const { data } = await axios.get(`${axiosUrl}/newsletter/${email.current.value}`);
            email.current.value = '';
            // window.alert(data);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <div className='pt-10 px-4 w-full overflow-x-hidden bg-bblack pb-20'>

            <div className='w-full max-w-[1100px] mx-auto mt-20 flex flex-col'>
                <div className='flex items-center justify-between gap-5'>
                    <img src={require('../../assets/logo.png')} alt="logo" className='w-[80px] object-contain' />
                    <div className='w-[50%] h-[1px] bg-[#89d7fb33] hidden md:block' />
                    <div className='hidden md:flex items-center justify-center gap-5'>
                        <a href="https://x.com/SwapFast_io">
                            <img src={require('../../assets/twitter.png')} alt="logo" className='h-8 circle border border-gray-600' />
                        </a>
                        <a href="https://t.me/SwapFast_io">
                            <img src={require('../../assets/telegram.png')} alt="logo" className='h-8 circle border border-gray-600' />
                        </a>
                        <a href="https://youtube.com/@swapfast">
                            <img src={require('../../assets/youtube.png')} alt="logo" className='h-8 circle border border-gray-600' />
                        </a>
                        <a href="https://medium.com/@SwapFast">
                            <img src={require('../../assets/medium.png')} alt="logo" className='h-8 circle border border-gray-600' />
                        </a>
                    </div>
                </div>
            </div>

            <div className='flex items-start gap-5 justify-between max-w-[1100px] mx-auto mt-10 flex-col md:flex-row'>
                <div className='md:block flex flex-col justify-between md:flex-row md:items-center w-[100%] md:w-auto'>
                    <div onClick={() => setFootItem(footItem === 1 ? 0 : 1)} className='flex items-center justify-between w-full cursor-pointer'>
                        <h1 className='monts font-bold text-lg mb-3'>Features</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mb-2 md:hidden opacity-70">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    <div className={`${footItem === 1 ? 'flex' : 'hidden md:flex'} flex-col items-start nunito text-sm gap-3 text-gray-300`}>
                        <a href="#">Smart Routing</a>
                        <a href="#">Non-Custodial</a>
                        <a href="#">Liquidity Pool</a>
                    </div>
                </div>
                <div className='md:block flex flex-col justify-between md:flex-row md:items-center w-[100%] md:w-auto'>
                    <div onClick={() => setFootItem(footItem === 2 ? 0 : 2)} className='flex items-center justify-between w-full cursor-pointer'>
                        <h1 className='monts font-bold text-lg mb-3'>Learn</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mb-2 md:hidden opacity-70">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    <div className={`${footItem === 2 ? 'flex' : 'hidden md:flex'} flex-col items-start nunito text-sm gap-3 text-gray-300`}>
                        <a href="https://ethereum.org/">Ethereum</a>
                        <a href="#">Roadmap</a>
                        <a href="#">How to buy SwapFast?</a>
                    </div>
                </div>
                <div className='md:block flex flex-col justify-between md:flex-row md:items-center w-[100%] md:w-auto'>
                    <div onClick={() => setFootItem(footItem === 3 ? 0 : 3)} className='flex items-center justify-between w-full cursor-pointer'>
                        <h1 className='monts font-bold text-lg mb-3'>Quick Links</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mb-2 md:hidden opacity-70 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    <div className={`${footItem === 3 ? 'flex' : 'hidden md:flex'} flex-col items-start nunito text-sm gap-3 text-gray-300`}>
                        <a href="#">Team</a>
                        <a href="#">Security</a>
                        <a href="#">Contact Us</a>
                    </div>
                </div>
                <div className='md:block flex flex-col justify-between md:flex-row md:items-center w-[100%] md:w-auto'>
                    <div onClick={() => setFootItem(footItem === 4 ? 0 : 4)} className='flex items-center justify-between w-full cursor-pointer'>
                        <h1 className='monts font-bold text-lg mb-3'>Socials</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mb-2 md:hidden opacity-70">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    <div className={`${footItem === 4 ? 'flex' : 'hidden md:flex'} flex-col items-start nunito text-sm gap-3 text-gray-300`}>
                        <a href="https://t.me/SwapFast_io">Telegram</a>
                        <a href="https://x.com/SwapFast_io">Twitter</a>
                        <a href="https://www.dextools.io/app/en/solana/pair-explorer/2u2C25ep8m7k2XzCqGJWpGQxm6vJYLHSDz6YAGUQWj4P?t=1717492003029">Dextool</a>
                        <a href="https://medium.com/@SwapFast">Medium</a>
                    </div>
                </div>
                {/* <div className='hidden md:flex items-center flex-col'>
                <h1 className='monts font-bold text-lg mb-3 text-center flex-col flex'>
                    <span>Get Started</span>
                    <span className='text-sm textgrad'>with SwapFast</span>
                </h1>
                <div className='border border-[#8583f345] bg-[#83a6f30a] rounded-xl w-[150px] flex-center'>
                   <img src={require('../../assets/logo.png')} alt="QRCode" className='w-[150px] object-contain' />
                </div>
            </div> */}
            </div>

            <div className='md:hidden flex items-center justify-around mt-10'>
                <a href="https://x.com/SwapFast_io">
                    <img src={require('../../assets/twitter.png')} alt="logo" className='h-8 circle border border-gray-600' />
                </a>
                <a href="https://t.me/SwapFast_io">
                    <img src={require('../../assets/telegram.png')} alt="logo" className='h-8 circle border border-gray-600' />
                </a>
                <a href="https://youtube.com/@swapfast">
                    <img src={require('../../assets/youtube.png')} alt="logo" className='h-8 circle border border-gray-600' />
                </a>
                <a href="https://medium.com/@SwapFast">
                    <img src={require('../../assets/medium.png')} alt="logo" className='h-8 circle border border-gray-600' />
                </a>
            </div>

            {/* <div className='w-full mt-12 md:hidden'>
            <div className='flex-col flex-center mb-5'>
                <h1 className='monts font-bold text-xl mb-3 text-center flex-col flex'>
                        <span>Get Started</span>
                        <span className='text-lg textgrad'>with SwapFast</span>
                </h1>
                <div className='border border-[#8583f345] bg-[#83a6f30a] rounded-xl w-[65%] flex-center'>
                    <img src={require('../../assets/logo.png')} alt="QRCode" className='w-full object-cover' />
                </div>
            </div>
            <a href="#" className='bgblue text-black w-[80%] mx-auto rounded-full py-3 block text-center monts font-bold text-lg'>Buy SwapFast</a>
        </div> */}

            <div className='w-[90%] h-[1px] bg-[#89d7fb14] mx-auto mb-10 md:mt-20 mt-5' />

            <div className='mx-auto max-w-[1100px]'>
                <div className='flex items-center justify-between'>
                    <p className='text-xs text-gray-400 text-left w-[90%] max-w-[650px]'>The purpose of this website is solely to display information regarding the products and services available on SwapFast. It is intended to offer access to any of SwapFast products and services. You may obtain access to every products and services on the SwapFast website.</p>
                    {/* <div className='hidden md:flex justify-center items-center gap-5'>
                    <img src={require('../../assets/Coinsult.png')} alt="Coinsult" className='h-10 opacity-85' />
                    <img src={require('../../assets/dexview.png')} alt="Dexview" className='h-10 opacity-85' />
                    <img src={require('../../assets/Pinksale.png')} alt="Pinksale" className='h-10 opacity-85' />
                    <img src={require('../../assets/solidproof.png')} alt="solidproof" className='h-14' />
                </div> */}
                </div>
                <div className='flex items-start gap-7 md:items-center md:justify-between mt-10 flex-col md:flex-row'>
                    <p className='text-xs text-gray-400 text-left w-[90%] max-w-[650px]'>Please note that the availability of the products and services on SwapFast is subject to jurisdictional limitations. SwapFast may not offer certain products, features and/or services on the SwapFast website in certain jurisdictions due to potential or actual regulatory restrictions.</p>
                    <form onSubmit={subscribe} className='gap-1 flex-center flex-col'>
                        <input ref={email} type="text" placeholder='Enter email' required className='px-2 py-1 placeholder:text-xs text-[16px] border border-orange rounded-xl outline-none bg-transparent' />
                        <button type='submit' className='text-left w-full ml-2 md:text-center cursor-pointer rounded-xl text-orange text-sm'>{isLoading ? 'Loading...' : 'Sign up for Newsletter'}</button>
                    </form>
                </div>
            </div>

            <div className='w-[90%] h-[1px] bg-[#89d7fb14] mx-auto mb-10 mt-10 max-w-[1100px]' />

            <div className='flex items-start md:items-center md:justify-between mt-10 mx-auto max-w-[1100px] flex-col md:flex-row gap-2'>
                <p className='text-xs md:text-sm text-gray-400 nunito text-left w-[650px]'>Copyright © 2024 SwapFast. All rights reserved.</p>
                <div className='text-xs md:text-sm flex-center gap-1 monts text-gray-400'>
                    <a href="#">Privacy Policy |</a>
                    <a href="#">Disclaimer</a>
                </div>
            </div>

        </div>
    )
}
