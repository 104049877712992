import React from 'react'

export default function Trusted() {
  return (
    <div className='min-h-[200px] text-black p-5 flex-center py-20'>
        <div className='flex items-center flex-col md:flex-row justify-center max-w-[950px] mx-auto w-full'>
            {/* <h1 className='monts text-3xl'>Trusted by millions <br /> all over the world</h1> */}
            <div className='flex-center gap-12 flex-wrap'>
                <div className='w-[40%] md:w-auto mx-auto flex-center flex-col text-center'>
                    <h2 className='text-orange text-4xl monts font-bold'>2M+</h2>
                    <p className='nunito text-sm opacity-40'>Potential users</p>
                </div>
                <div className='w-[40%] md:w-auto mx-auto flex-center flex-col text-center'>
                    <h2 className='text-orange text-4xl monts font-bold'>10+</h2>
                    <p className='nunito text-sm opacity-40'>Blockchains</p>
                </div>
                <div className='w-[40%] md:w-auto mx-auto flex-center flex-col text-center'>
                    <h2 className='text-orange text-4xl monts font-bold'>15+</h2>
                    <p className='nunito text-sm opacity-40'>DEX</p>
                </div>
                <div className='w-[40%] md:w-auto mx-auto flex-center flex-col text-center'>
                    <h2 className='text-orange text-4xl monts font-bold'>7+</h2>
                    <p className='nunito text-sm opacity-40'>Bridges</p>
                </div>
                <div className='w-[40%] md:w-auto mx-auto flex-center flex-col text-center'>
                    <h2 className='text-orange text-4xl monts font-bold'>4.7</h2>
                    <p className='nunito text-sm opacity-40'>Rating out of 5</p>
                </div>
                <div className='w-[40%] md:w-auto mx-auto flex-center flex-col text-center'>
                    <h2 className='text-orange text-4xl monts font-bold'>100M+</h2>
                    <p className='nunito text-sm opacity-40'>Potential Trading Volume</p>
                </div>
            </div>
        </div>
    </div>
  )
}
