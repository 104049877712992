import React from 'react'

export default function About() {
  return (
      <div className='flex-center p-5 pb-20'>
        <div className='text-black border border-black border-opacity-10  flex flex-col md:flex-row justify-between gap-10 items-center bg-grad w-full max-w-[900px] py-5 px-10 md:mr-5 rounded-2xl shadow-xl'>
            <div className='max-w-[400px] text-center md:text-left'>
                <h1 className='monts text-4xl'>How it Works</h1>
                <div>
                  <div className='flex flex-col items-start'>
                      <h1 className='monts text-[12px] opacity-30 mt-4'>1. Select Assets:</h1>
                      <p className='monts text-sm'>Choose your assets and amounts</p>
                  </div>
                  <div className='flex flex-col items-start'>
                      <h1 className='monts text-[12px] opacity-30 mt-4'>2. Choose Networks:</h1>
                      <p className='monts text-sm'> Select blockchain networks.</p>
                  </div>
                  <div className='flex flex-col items-start'>
                      <h1 className='monts text-[12px] opacity-30 mt-4'>3. Aggregator Scan:</h1>
                      <p className='monts text-sm'>SwapFast finds best prices across DEXs.</p>
                  </div>
                  <div className='flex flex-col items-start'>
                      <h1 className='monts text-[12px] opacity-30 mt-4'>4. Optimal Trade:</h1>
                      <p className='monts text-sm'>Execute trade with minimal slippage</p>
                  </div>
                  <div className='flex flex-col items-start'>
                      <h1 className='monts text-[12px] opacity-30 mt-4'>5. Transaction Confirmation:</h1>
                      <p className='monts text-sm'>Confirm and monitor the transaction.</p>
                  </div>
                  <div className='flex flex-col items-start'>
                      <h1 className='monts text-[12px] opacity-30 mt-4'>6. Completed Swap:</h1>
                      <p className='monts text-sm'>Enjoy seamless swaps.</p>
                  </div>
                  <div className='my-5 w-[90%] flex-center'>
                    <a href='https://app.swapfast.io' className='text-sm monts text-center py-2 rounded-full text-black bg-orange w-full'>Get Started</a>
                  </div>
                </div>
            </div>
            <img src={require('../../assets/swap.png')} alt="" className='object-contain h-[370px]' />
        </div>
    </div>
  )
}
