import React from 'react'

export default function Featured() {
  return (
    <section id="press" className="pt-20 backers">
      <h1 className='monts font-extrabold text-[3rem] text-center mx-10 md:mx-auto'>
        Featured On
      </h1>
      {/* MOVING IMAGES */}
      <div className="w-full mx-auto mt-10">
        <div className="backersMobile flex-col">
          <div className="scroll-parent">
            <div className="scroll-element primary">

              {/* ROW 1 */}
              <a href="https://www.benzinga.com/pressreleases/24/05/g39044732/introducing-swapfast-revolutionizing-cross-chain-transactions" target="_blank">
                <img src={require('../../assets/partners/benzinga.png')} alt="Benzinga" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://www.crypto-reporter.com/newsfeed/introducing-swapfast-revolutionizing-cross-chain-transactions-65167" target="_blank">
                <img src={require('../../assets/partners/cryptoreporter.png')} alt="Crypto Reporter" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://www.benzinga.com/pressreleases/24/05/g39044732/introducing-swapfast-revolutionizing-cross-chain-transactions" target="_blank">
                <img src={require('../../assets/partners/benzinga.png')} alt="Benzinga" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://coinmarketcap.com/community/articles/66564668b2e25e3074ad3d01" target="_blank">
                <img src={require('../../assets/partners/CoinMarketCap.png')} alt="Coinmarketcap" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://bitcointalk.org/index.php?topic=5498076" target="_blank">
                <img src={require('../../assets/partners/btctalk.png')} alt="Market Watch" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://finance.yahoo.com/news/introducing-swapfast-revolutionizing-cross-chain-180000859.html" target="_blank">
                <img src={require('../../assets/partners/Yahoo Finance.png')} alt="Yahoo Finance" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://www.investorsobserver.com/news/qm-pr/8392431059366874" target="_blank">
                <img src={require('../../assets/partners/iobs.png')} alt="investors Observer" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://bitswage.com/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/bitwage.png')} alt="Bit Wage" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://bsc.exchange/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/bsc.png')} alt="BSC" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://cryptoadventure.co.uk/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/cryptoadvent.png')} alt="Crypto Adventure" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://wagebits.com/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/wagebits.webp')} alt="Wage Bits" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://cryptoddy.com/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/cryptoddy.png')} alt="Cryptoddy" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://coinpress.media/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/coinpress.png')} alt="Coinpress" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://go.chinesewire.com/article/Introducing-SwapFast-Revolutionizing-Cross-Chain-Transactions?storyId=66561c7acfb8d391fa40bd46" target="_blank">
                <img src={require('../../assets/partners/chinesewire.png')} alt="Chinesewire" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://ceo.ca/@globenewswire/introducing-swapfast-revolutionizing-cross-chain-transactions" target="_blank">
                <img src={require('../../assets/partners/ceo.ca.png')} alt="CEO.CA" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>

              {/* ROW 2 */}
              <a href="https://www.benzinga.com/pressreleases/24/05/g39044732/introducing-swapfast-revolutionizing-cross-chain-transactions" target="_blank">
                <img src={require('../../assets/partners/benzinga.png')} alt="Benzinga" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://www.crypto-reporter.com/newsfeed/introducing-swapfast-revolutionizing-cross-chain-transactions-65167" target="_blank">
                <img src={require('../../assets/partners/cryptoreporter.png')} alt="Crypto Reporter" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://www.benzinga.com/pressreleases/24/05/g39044732/introducing-swapfast-revolutionizing-cross-chain-transactions" target="_blank">
                <img src={require('../../assets/partners/benzinga.png')} alt="Benzinga" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://coinmarketcap.com/community/articles/66564668b2e25e3074ad3d01" target="_blank">
                <img src={require('../../assets/partners/CoinMarketCap.png')} alt="Coinmarketcap" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://bitcointalk.org/index.php?topic=5498076" target="_blank">
                <img src={require('../../assets/partners/btctalk.png')} alt="Market Watch" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://finance.yahoo.com/news/introducing-swapfast-revolutionizing-cross-chain-180000859.html" target="_blank">
                <img src={require('../../assets/partners/Yahoo Finance.png')} alt="Yahoo Finance" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://www.investorsobserver.com/news/qm-pr/8392431059366874" target="_blank">
                <img src={require('../../assets/partners/iobs.png')} alt="investors Observer" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://bitswage.com/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/bitwage.png')} alt="Bit Wage" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://bsc.exchange/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/bsc.png')} alt="BSC" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://cryptoadventure.co.uk/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/cryptoadvent.png')} alt="Crypto Adventure" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://wagebits.com/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/wagebits.webp')} alt="Wage Bits" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://cryptoddy.com/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/cryptoddy.png')} alt="Cryptoddy" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://coinpress.media/introducing-swapfast-revolutionizing-cross-chain-transactions/" target="_blank">
                <img src={require('../../assets/partners/coinpress.png')} alt="Coinpress" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://go.chinesewire.com/article/Introducing-SwapFast-Revolutionizing-Cross-Chain-Transactions?storyId=66561c7acfb8d391fa40bd46" target="_blank">
                <img src={require('../../assets/partners/chinesewire.png')} alt="Chinesewire" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="https://ceo.ca/@globenewswire/introducing-swapfast-revolutionizing-cross-chain-transactions" target="_blank">
                <img src={require('../../assets/partners/ceo.ca.png')} alt="CEO.CA" className="max-w-[180px] md:max-w-[250px] h-[40px] md:h-[60px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
