import React from 'react'

function Header({ connect, isConnected, address }) {
  return (
    <div className='w-full max-w-[1100px] mx-auto m-5 flex items-center justify-between'>
      <a href="https://www.swapfast.io/">
        <img src={require('../assets/logo.png')} alt="logo" className='w-10 md:w-12 object-contain' />
      </a>
      <div className='monts items-center justify-center gap-5 text-gray-300 hidden md:flex text-sm'>
        <a href="#">Staking</a>
        <a href="#">Document</a>
        <a href="#">Community</a>
        <a href="#">Roadmap</a>
        <a href="#">Tokenomics</a>
      </div>
      <div className='bg-orange text-xs py-1 px-7 text-bblack rounded-full cursor-pointer' onClick={connect}>
        {isConnected ? (`${address.slice(0,4)}...${address.slice(38)}`) : 'Connect Wallet' }
      </div>
    </div>
  )
}

export default Header