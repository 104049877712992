import React from 'react'

export default function Tokenomics() {
  return (
    <section id="tokenomics" className="py-20 w-full max-w-[1100px] mx-auto p-3 md:p-5 md:mt-20 text-black">
        <div className="flex-center text-center text-4xl md:text-5xl monts font-bold">
            <h1 className="blue-text">SwapFast<br />Utility Token</h1>
        </div>
        <div className="flex items-center flex-col md:flex-row justify-between md:mb-24 md:mt-10 gap-5">
            <div className="flex-center gap-5 mt-10 flex-wrap">
                <div className="flex flex-col w-full md:w-auto gap-5">
                    <div data-aos="fade-up" data-aos-duration="1500" className="flex-col flex-center shadow-lg t-grad w-[100%] md:w-[230px] p-5 rounded-xl border border-black border-opacity-10 text-center">
                        <p className="captext monts">Token name</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-2 opacity-15">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                        <p className="text-xl monts ">SwapFast</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500" className="flex-col flex-center shadow-lg t-grad w-[100%] md:w-[230px] p-5 rounded-xl border border-black border-opacity-10 text-center">
                        <p className="captext monts">Token Symbol</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-2 opacity-15">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                        <p className="text-xl monts ">SWAPFAST</p>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-auto gap-5">
                    <div data-aos="fade-up" data-aos-duration="1500" className="flex-col flex-center shadow-lg t-grad w-[100%] md:w-[230px] p-5 rounded-xl border border-black border-opacity-10 text-center">
                        <p className="captext monts">Token Supply</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-2 opacity-15">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                        <p className="text-xl monts ">5 Million</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500" className="flex-col flex-center shadow-lg t-grad w-[100%] md:w-[230px] p-5 rounded-xl border border-black border-opacity-10 text-center">
                        <p className="captext monts">Total Tax</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-2 opacity-15">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                        <p className="text-xl monts ">0%</p>
                    </div>
                </div>
            </div>
            <div className="flex-1 flex-center flex-col mt-5 md:mt-10 w-full">
            <img src={require('../../assets/token.png')} alt="" />
                <div className="flex-center gap-3 mb-3">
                    <p className="dmsans text-gray-400 text-[10px] md:text-xs contract_add">9MCBk8GT1ajSz4ivxdAhY5Xsd1ZpRpbVtNbycJHVgkYj</p>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 text-white copyBtn cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                    </svg>                          
                </div>
                <a href="https://www.dextools.io/app/en/solana/pair-explorer/2u2C25ep8m7k2XzCqGJWpGQxm6vJYLHSDz6YAGUQWj4P?t=1717492003029" className=" border border-black border-opacity-10 text-center flex-center bg-grad w-[95%] max-w-[300px] p-2 text-sm rounded-xl monts">Dextools</a>
            </div>
        </div>
    </section>
  )
}
