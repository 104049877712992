import { useState } from 'react'
import About from './About'
import Cookie from './Cookie'
import Footer from './Footer'
import Hero from './Hero'
import Navbar from './Navbar'
import Roadmap from './Roadmap'
import Tokenomics from './Tokenomics'
import Trusted from './Trusted'
import Featured from './Featured'

export default function HomePage() {
  const cookieState = window.localStorage.getItem('cookie');
  const [isCookie, setIsCookie] = useState(cookieState);
  return (
    <div className='bg-white'>
      <Navbar />
      <Hero />
      <Trusted />
      <About />
      <Tokenomics />
      <div className='bg-bblack pt-0.5'>
        <Roadmap />
        <Featured />
        <Footer />
      </div> 
      {!isCookie && <Cookie setIsCookie={setIsCookie} />}
    </div>
  )
}
